/* eslint-disable global-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Define translation resources for different languages
const resources = {
  en: {
    translation: require('./locales/translated/en/translation.json'),
  },
  bg: {
    translation: require('./locales/translated/bg/translation.json'),
  },
  ro: {
    translation: require('./locales/translated/ro/translation.json'),
  },
  pl: {
    translation: require('./locales/translated/pl/translation.json'),
  },
  lt: {
    translation: require('./locales/translated/lt/translation.json'),
  },
  ru: {
    translation: require('./locales/translated/ru/translation.json'),
  },
  ua: {
    translation: require('./locales/translated/ua/translation.json'),
  },
};

// Initialize i18next with language detection and React support
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources, // Provide translation resources
    fallbackLng: 'en', // Fallback language if translation is not available for the detected language
    debug: false, // Enable debug mode (console logs) for i18next

    // Interpolation configuration
    interpolation: {
      escapeValue: false, // Disable HTML escaping, not needed for React as it escapes by default
    },

    returnNull: false, // Do not return null for missing translations
    nsSeparator: ':::', // Namespace separator for translations
    keySeparator: '::', // Key separator for translations
  });

// Set the default locale to 'en'
i18n.defaultLocale = 'en';

// If the language has a region (e.g., 'en-US'), change it to 'en'
if (i18n.language.split('-').length > 1) {
  i18n.changeLanguage('en');
}

export default i18n;
